<template>
    <modal ref="modalCrearGrupoPermiso" titulo="Añadir grupo de permiso" adicional="Guardar">
        <ValidationObserver ref="createGrupoPermiso">
            <div class="row m-3 f-15">
                <div class="col-12">
                    <p class="input-label-top">Nombre</p>
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                        <el-input v-model="nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                    <br/>
                    <p class="input-label-top">Descripcion</p>
                    <ValidationProvider v-slot="{errors}" name="descripcion" rules="required">
                        <el-input v-model="descripcion" type="textarea" :autosize="{ minRows: 3 }" class="w-100" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            nombre: "",
            descripcion: ""
        }
    },
    methods: {
        toggle(){
            this.$refs.createGrupoPermiso.reset()
            this.$refs.modalCrearGrupoPermiso.toggle()
        },
    }
}
</script>